<template>
  <div v-if="employeesEnquirySalariesPaidsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("treasuryName") }}</th>
            <th>{{ $t("EmployeesEnquirySalariesPaids.paidValue") }}</th>
            <th>{{ $t("EmployeesEnquirySalariesPaids.remaning") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("userMakeAction") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>
              {{ $t("cancel") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              employeesEnquirySalariesPaid, index
            ) in employeesEnquirySalariesPaidsData"
            :key="employeesEnquirySalariesPaid.enquirySalariesPaidToken"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{
                isDataExist(employeesEnquirySalariesPaid.treasuryNameCurrent)
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  employeesEnquirySalariesPaid.enquirySalariePaidValue
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  employeesEnquirySalariesPaid.enquirySalariePaidRemaning
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  employeesEnquirySalariesPaid.enquirySalariePaidNotes
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  employeesEnquirySalariesPaid.employeeMakeActionNameCurrent
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  employeesEnquirySalariesPaid.actionDate,
                  employeesEnquirySalariesPaid.actionTime
                )
              }}
            </td>

            <td>
              <button
                v-if="
                  checkPrivilege(
                    usersEstablishmentPrivilegeData
                      .usersPrivilegeEmployeesEnquirySalariesPaid
                      .employeesEnquirySalariesPaidDelete
                  )
                "
                v-b-modal.EmployeesEnquirySalariesPaidCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="
                  setEmployeesEnquirySalariesPaidData(
                    employeesEnquirySalariesPaid
                  )
                "
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import {
  isDataExist,
  moneyFormat,
  formateDateTimeLang,
} from "@/utils/functions";
export default {
  name: "EmployeesEnquirySalariesPaidsTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: [
    "employeesEnquirySalariesPaidsData",
    "employeesEnquirySalariesPaidData",
    "pagination",
  ],
  methods: {
    isDataExist,
    moneyFormat,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    setEmployeesEnquirySalariesPaidData(employeesEnquirySalariesPaidData) {
      this.$emit(
        "setEmployeesEnquirySalariesPaidData",
        employeesEnquirySalariesPaidData
      );
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
